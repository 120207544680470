<template>
  <main class="app__page contacts">
    <div class="contacts__semicircles"></div>
    <div class="contacts__container">
      <h1 class="contacts__title title">
        {{ $t("pages.contacts.title") }}
      </h1>

      <div class="contacts__item contacts__item--mb60">
        <div class="contacts__caption">
          {{ $t("pages.contacts.address_caption") }}
        </div>
        <div class="contacts__address">
          {{ address }}
        </div>
      </div>

      <div class="contacts__items">
        <div v-if="phone || email" class="contacts__item">
          <div class="contacts__caption">
            {{ $t("pages.contacts.contacts_caption") }}
          </div>
          <div class="contacts__info">
            <a
              v-if="phone"
              :href="`tel:${phoneNumber}`"
              class="contacts__phone"
            >
              <svg-icon
                width="54"
                height="54"
                name="contacts_phone"
                class="contacts__icon"
              />
              {{ phone }}
            </a>

            <a v-if="email" :href="`mailto:${email}`" class="contacts__mail">
              <svg-icon
                width="54"
                height="54"
                name="contacts_mail"
                class="contacts__icon"
              />
              {{ email }}
            </a>
          </div>
        </div>

        <div v-if="schedule" class="contacts__item">
          <div class="contacts__caption">
            {{ $t("pages.contacts.time_caption") }}
          </div>
          <div class="contacts__time">
            <svg-icon
              width="54"
              height="54"
              name="contacts_time"
              class="contacts__icon"
            />
            {{ $t("pages.contacts.time") + " " + schedule }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";
export default {
  name: "contacts",
  mixins: [pageLoaded],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    contacts() {
      return this.$store.state.contacts;
    },
    address() {
      return this.contacts.address[this.locale] || "";
    },
    email() {
      return this.contacts.email || "";
    },
    phone() {
      return this.contacts.phone || "";
    },
    phoneNumber() {
      return this.$store.getters.phoneNumber;
    },
    schedule() {
      return this.$store.getters.schedule;
    }
  }
};
</script>

<style lang="less">
.contacts {
  position: relative;
  padding: 270px 0px 50px;
  @media @bw1510 {
    padding: 185px 0 30px;
  }
  @media @w1510bh750 {
    padding: 185px 0 30px;
  }
  @media @bw1170 {
    padding: 215px 0 30px;
  }
  @media @bw1020 {
    padding: 80px 0 30px;
  }
  @media @bw768 {
    padding: 40px 0 20px;
  }
  @media @bw400 {
    padding: 30px 0 20px;
  }
  &__semicircles {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 434px;
    height: 330px;
    overflow: hidden;
    @media @bw1510 {
      width: 155px;
      height: 169px;
    }
    @media @w1510bh750 {
      width: 155px;
      height: 169px;
    }
    @media @bw1020 {
      width: 395px;
      height: 281px;
    }
    @media @bw768 {
      width: 175px;
      height: 179px;
    }
    @media @bw400 {
      width: 100px;
      height: 103px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 777px;
      height: 773px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1510 {
        width: 407px;
        height: 404px;
      }
      @media @w1510bh750 {
        width: 407px;
        height: 404px;
      }
      @media @bw1020 {
        top: 0;
        left: 0;
        width: 777px;
        height: 773px;
      }
      @media @bw768 {
        top: -42px;
        left: -33px;
      }
      @media @bw400 {
        top: -20px;
        left: -26px;
        width: 464px;
        height: 460px;
      }
    }
    &::after {
      top: 120px;
      left: 60px;
      border: 1px solid @gold;
      @media @bw1510 {
        top: 52px;
        left: 36px;
      }
      @media @w1510bh750 {
        top: 52px;
        left: 36px;
      }
      @media @bw1020 {
        top: 120px;
        left: 60px;
        width: 777px;
        height: 773px;
      }
      @media @bw768 {
        top: 70px;
        left: -60px;
      }
      @media @bw400 {
        top: 0px;
        left: -85px;
      }
    }
  }
  &__container {
    .container;
  }
  &__title {
    margin-bottom: 80px;
    @media @bw1510 {
      margin-bottom: 50px;
    }
    @media @w1510bh750 {
      margin-bottom: 50px;
    }
    @media @bw1170 {
      margin-bottom: 80px;
    }
    @media @bw768 {
      margin-bottom: 60px;
    }
    @media @bw400 {
      margin-bottom: 30px;
    }
  }
  &__caption {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.2;
    color: @white_60;
  }
  &__items {
    display: flex;
    @media @bw1170 {
      flex-direction: column;
    }
    .contacts__item {
      margin-right: 100px;
      @media @bw1170 {
        margin-right: 0;
        margin-bottom: 40px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__item {
    &--mb60 {
      margin-bottom: 60px;
      @media @bw1510 {
        margin-bottom: 40px;
      }
      @media @w1510bh750 {
        margin-bottom: 40px;
      }
    }
  }
  &__address {
    max-width: 660px;
    width: 100%;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
    @media @bw768 {
      max-width: 100%;
      font-size: 24px;
      line-height: 1.3;
    }
  }
  &__info {
    display: flex;
    @media @bw768 {
      flex-direction: column;
    }
  }
  &__phone {
    margin-right: 50px;
    @media @bw768 {
      margin-right: 0;
      margin-bottom: 30px;
    }
    @media @bw400 {
      margin-bottom: 20px;
    }
  }
  &__mail,
  &__phone,
  &__time {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: @font2;
    font-weight: 700;
    font-size: 22px;
    line-height: 0.92;
    color: @white;
  }
  &__mail,
  &__phone {
    transition: color 0.2s;
    &:hover {
      color: @gold;
    }
  }
  &__icon {
    margin-right: 20px;
    color: @gold;
    @media @bw400 {
      display: none;
    }
  }
}
</style>
