export const pageLoaded = {
  data() {
    return {
      loadQty: 1, // смонтировано
      loaded: 0,
      isLoaded: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = this.loaded + 1;
    });
  },
  watch: {
    loaded: {
      handler: function (val) {
        if (val >= this.loadQty && !this.isLoaded) {
          this.isLoaded = true;

          if (this.observer) {
            this.observe();
          }

          this.$emitter.emit("hide-preloader");
          document.dispatchEvent(new Event("render-event"));
        }
      },
      immediate: true
    }
  }
};
